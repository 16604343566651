import React from "react"
import { navigate } from "gatsby-link"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Radio from "@material-ui/core/Radio"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Datetime from "react-datetime"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"

import workStyle from "assets/jss/material-kit-react/views/sponsorshipPageSections/workStyle.jsx"
import { Checkbox } from "@material-ui/core";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class TournamentSignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: '',
      photo: false,
      covid: false,
      whistles: false,
      sanitize: false,
      fee: false,
      member: false,
      partners_name: "",
      partner_member: false,
      gender: "Male"
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section} style={{ backgroundColor: "white", padding: '0.25rem' }}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Registration for Tournament 10th/11th October 2020</h2>
            <h4> Please note that registration has now closed as the tournament is full. </h4>
            <form
              name="tournament"
              method="post"
              data-netlify="true"
              action="/success"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="bot" value="contact" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <GridContainer>
                {/* Name */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "name",
                      onChange: e => this.setState({ name: e.target.value }),
                    }}
                  />
                </GridItem>
                {/* Partners Name */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Partners Name"
                    id="partners_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "partners_name",
                      onChange: e => this.setState({ partners_name: e.target.value }),
                    }}
                  />
                </GridItem>
                {/* Member */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Tick here if you're a West Coast Member (and have paid your membership fees).
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.member}
                          onChange={() =>
                            this.setState({ member: !this.state.member })
                          }
                          value={this.state.member}
                          name="member"
                          aria-label="member"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Yes"
                    />
                  </GridItem>
                </GridItem>

                {/* Partner Member */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Tick here if you're partner is a West Coast Member (and have paid their membership fees).
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.partner_member}
                          onChange={() =>
                            this.setState({ partner_member: !this.state.partner_member })
                          }
                          value={this.state.partner_member}
                          name="partner_member"
                          aria-label="partner_member"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="Yes"
                    />
                  </GridItem>
                </GridItem>
                {/* Tournament Entry */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Are you entering for the male (Saturday 10th October 2020) or female (Sunday 11th October 2020) tournament?
                  </InputLabel>
                  <input
                    style={{ display: "none" }}
                    value={this.state.gender}
                    name="gender"
                  />
                  <CustomDropdown
                    id="gender"
                    buttonText={this.state.gender}
                    hoverColor="info"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="gender"
                    buttonProps={{
                      className: classes.navLink,
                      color: "transparent",
                      width: "100%",
                      name: "gender",
                    }}
                    onClick={e => this.setState({ gender: e })}
                    dropdownList={[
                      "Male"
                    ]}
                  />
                </GridItem>
                {/* Email */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "email",
                      value: this.state.email,
                      onChange: e => this.setState({ email: e.target.value }),
                    }}
                  />
                </GridItem>
                {/* Phone Number */}
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Phone Number"
                    id="phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "phone",
                      value: this.state.phone,
                      onChange: e => this.setState({ phone: e.target.value }),
                    }}
                  />
                </GridItem>
                {/* Photo Consent */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    I give my consent for photographs of me at the tournament to be used on the clubs social media and websites.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.photo}
                          onChange={() =>
                            this.setState({ photo: !this.state.photo })
                          }
                          value={this.state.photo}
                          name="photo"
                          aria-label="Photo Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I consent"
                    />
                  </GridItem>
                </GridItem>
                {/* Covid Consent */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Both athletes will abide by the Covid regulations and guidance set out by <a href="https://www.scottishvolleyball.org/wp-content/uploads/2020/08/SVA-COVID-19-Exit-Strategy-Playing-it-Safe-Phase-3-Updated-24th-August.pdf" target="_blank" rel="noopener">Scottish Volleyball.</a>
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.covid}
                          onChange={() =>
                            this.setState({ covid: !this.state.covid })
                          }
                          value={this.state.covid}
                          name="covid"
                          aria-label="Covid Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I agree to these terms"
                    />
                  </GridItem>
                </GridItem>
                {/* Sanitiser Consent */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Athletes will provide their own hand-sanitiser.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.sanitize}
                          onChange={() =>
                            this.setState({ sanitize: !this.state.sanitize })
                          }
                          value={this.state.sanitize}
                          name="sanitize"
                          aria-label="Sanitizer Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I agree to these terms"
                    />
                  </GridItem>
                </GridItem>
                {/* Whistles Consent */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Athletes will bring a whistle for refereeing games, which cannot be shared with other athletes.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.whistles}
                          onChange={() =>
                            this.setState({ whistles: !this.state.whistles })
                          }
                          value={this.state.whistles}
                          name="whistles"
                          aria-label="whistles Consent"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I agree to these terms"
                    />
                  </GridItem>
                </GridItem>
                {/* Fee Consent */}
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    I agree that our entry is only secure once payment has been submitted.
                  </InputLabel>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.fee}
                          onChange={() =>
                            this.setState({ fee: !this.state.fee })
                          }
                          value={this.state.fee}
                          name="fee"
                          aria-label="fee"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.checkbox,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="I agree to these terms"
                    />
                  </GridItem>
                </GridItem>
              </GridContainer>
              <GridContainer justify="center">
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.textCenter}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={true
                    }
                  >
                    Submit Registration
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(workStyle)(TournamentSignUp)
